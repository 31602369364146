import { VENDOR_LISTS } from './vendor-collaboration';

// invitation flag feature
export const ENABLE_SHIFT_TIME_RECEPTION = false;
export const ENABLE_ANNOUNCEMENT_MODE = true;
export const ENABLE_PARTNER_MODE = true;

// Giftcard flag feature
export const ENABLE_SEND_GIFT = true;
export const ENABLE_BANK_TRANSFER = true;
export const ENABLE_GIFT_CONFIRMATION = true;

// generator link flag feature
export const DISABLE_CUSTOM_LINK = false;
export const DISABLE_GENERATOR_INVITATION = false;
export const DISABLED_QR_GENERATOR_IN_PRODUCTION = true;
export const ENABLE_GUEST_LIST = true;

// Global feature invitation
export const DISABLED_MUSIC_IN_DEVELOPMENT = true;
export const MUSLIM_INVITATION = true; // change to `true` if you want to use muslim wording
export const DEFAULT_LANGUAGE = 'id'; // change to `en` if you want to set default to English
export const ENABLE_MULTI_LANGUAGE = false;
export const ENABLE_QR_INVITATION = true;
export const ENABLE_MUSIC_BACKSOUND = true;

export const ENABLE_LIVE_STREAMING = false; //LIVE STREAMING
export const ENABLE_GIFT_CARD = false; //GIFTCARD
export const ENABLE_DARKMODE_NOTIFICATION = true;

export const ENABLE_LOVE_STORY = false; //LOVE STORY
export const ENABLE_LOVE_STORY_CONTENT = false; // change to `true` if you want to add button to content
export const ENABLE_RSVP = true; //RSVP
export const ENABLE_COVID_PROTOCOL = false;
export const ENABLE_GIF_COUNTDOWN = false; //VIDEO GIF
export const ENABLE_COLLABORATION = false; //COLLABORATION
export const SELECTED_VENDOR_COLLABORATION = VENDOR_LISTS['aksara']; // change 'aksara' to any of the vendor inside VENDOR_LISTS if want to use a new vendor, just copy and add under the last vendor
export const ENABLE_GUEST_PERSONALIZATION = false; // change to `en` if you want to set personalization guest
export const ENABLE_IG_FILTER = true; //FILTER INSTAGRAM